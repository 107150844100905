import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ButtonGroup,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import Swal from "sweetalert2";
import { useLoading } from "../../utils/LoadingContext";

export const Medals = () => {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();
  const navigate2 = useNavigate();
  const { setLoading } = useLoading();

  const handleEdit = (data) => {
    navigate2("/medals/add", { state: { data } });
  };
  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this medal? This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteUrl = `${apiAuth.deleteMedal}/${id}`;
        console.log(deleteUrl)
        networkRequest(deleteUrl, (t) => {
          setLoading(t);
          console.log("Deleted");
          Swal.fire({
            icon: "success",
            title: "Deleted!",
            text: "Medal has been deleted.",
          });
          fetchMedals();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          icon: "info",
          title: "Cancelled",
          text: "Your medal is safe!",
        });
      }
    });
  };

  const fetchMedals = async () => {
    await networkRequest(
      apiAuth.getMedal,
      (t) => {
        setLoading(t);
      },
      handleResponse
    );
  };

  const handleResponse = (res) => {
    console.log(res);
    setRows(res.data);
  };

  useEffect(() => {
    fetchMedals();
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        gap="15px"
        alignItems="center"
        sx={{ pt: 2, pr: 2, m: 2, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Medals</h1>
        </Grid>
        <Grid item sx={{ marginTop: { xs: "10px" } }}>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/medals/add");
            }}
          >
            Add Medal
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 0, pr: 2, maxWidth: "100vw" }}
      >
        <Grid item></Grid>
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            sx={{
              width: "200px",
              mt: 2,
              mb: 2,
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "13px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title="Filter">
            <Button variant="contained" sx={{ m: 2, mr: 0 }}>
              <FilterAltIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ py: 2 }}>
                  NAME
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  LIMIT
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  DESCRIPTION
                </TableCell>
                <TableCell align="center" sx={{ py: 2 }}>
                  ACTION
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(rows) && rows.length > 0 ? (
                rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.limit}</TableCell>
                    <TableCell align="center">{row.description}</TableCell>
                    <TableCell align="center">
                      <ButtonGroup>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          onClick={() => {
                            handleEdit(row);
                          }}
                        >
                          <EditIcon />
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ mr: 2 }}
                          onClick={() => {
                            handleDelete(row.id);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No Medals Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TableContainer>
    </>
  );
};
