import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardCard from "../components/DashboardTile";
import { People, RssFeedSharp, School } from "@mui/icons-material";
import { networkRequest } from "../utils/network_request";
import { apiAuth } from "../utils/urls";
import { useLoading } from "../utils/LoadingContext";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function Dashboard() {
  const [data, setData] = useState([]);

  const { setLoading } = useLoading();

  const fetchData = async () => {
    await networkRequest(
      apiAuth.dashboardData,
      (t) => {
        setLoading(t);
      },
      (res) => {
        setData(res.data)
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      {/* Dashboard Tiles */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xs={6} md={3}>
          <DashboardCard icon={School} title="No of Users" value={data.users} />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard icon={People} title="No of Posts" value={data.posts} />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard
            icon={RssFeedSharp}
            title="Posts Verified"
            value={data.posts - data.unverified}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <DashboardCard
            icon={RssFeedSharp}
            title="Pending Post"
            value={data.unverified}
          />
        </Grid>
      </Grid>

      {/* Bar Chart */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data.chart_data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="posts" fill="#4caf50" />
              <Bar dataKey="users" fill="#2196f3" />
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
