import React, { useEffect, useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Box,
  Modal,
  Stack,
  Pagination,
  ButtonGroup,
  Switch,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { networkRequest } from "../../utils/network_request";
import { apiAuth } from "../../utils/urls";
import { useLoading } from "../../utils/LoadingContext";
import { debounce } from "lodash";
import { domain } from "../../utils/domain";
import { use } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  height: "auto",
  transform: "translate(-50%, -50%)",
  p: 3,
  backgroundColor: "white",
};

export default function Leaders() {
  const { setLoading } = useLoading();
  const [users, setLeaders] = useState([]);
  const [referralData, setReferralData] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(10);
  const [medals, setMedals] = useState([]);

  const getMedals = async () => {
    try {
      await networkRequest(
        apiAuth.getMedal,
        (t) => setLoading(t),
        (response) => {
          setMedals(response.data);
        }
      );
    } catch (error) {
      console.error("Error fetching medals:", error);
    }
  };



  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpen = (photo) => {
    setReferralData(photo);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const fetchLeaders = async (search = "", medal = "") => {
    try {
      const url = `${apiAuth.getLeaders}`
      await networkRequest(
        url,
        (t) => setLoading(t),
        (response) => {
          setLeaders(response.data);
          setTotalPages(response.pagination.total_pages);
        },
        'get',
        {
          page: currentPage,
        }
      );
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    getMedals();
  }, []);

  useEffect(() => {
    fetchLeaders();
  }, [currentPage]);

  const handleSearch = debounce((event) => {
    const searchTerm = event.target.value;
    fetchLeaders(searchTerm);
  }, 500);

  const handleMedalChange = (event) => {
    setPage(1); // Reset page to 1 when changing filters
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ pr: 2, m: 2, mt: 0, maxWidth: "100vw" }}
      >
        <Grid item>
          <h1>Leaders</h1>
        </Grid>
        <Grid item>
          <TextField
            id="outlined-search"
            label="Search"
            type="search"
            onChange={handleSearch}
            sx={{
              width: "250px",
              mr: 2,
              "& .MuiInputBase-root": {
                height: "40px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "1.5",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <TableContainer
        component={Paper}
        sx={{ maxWidth: "100vw", overflow: "auto" }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ py: 2 }}>USER ID</TableCell>
              <TableCell sx={{ py: 2 }}>PROFILE</TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                USER NAME
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                MOBILE NO
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                MEDAL
              </TableCell>
              <TableCell align="center" sx={{ py: 2 }}>
                Coins
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 ? (
              users.map((user) => (
                <TableRow
                  key={user.id}
                  onClick={() =>
                    handleOpen(
                      user.referred_users
                    )
                  }
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: "60px",
                    cursor: "pointer",
                  }}
                >
                  <TableCell>{user.id}</TableCell>
                  <TableCell>
                    <Avatar
                      src={
                        `${domain}/${user.profile_pic}` ||
                        "https://via.placeholder.com/150"
                      }
                    />
                  </TableCell>
                  <TableCell align="center">{user.name}</TableCell>
                  <TableCell align="center">{user.contact}</TableCell>
                  <TableCell align="center">{user.medal || "N/A"}</TableCell>
                  <TableCell align="center">{user.total_coins || "N/A"}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No users found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Stack spacing={2} sx={{ alignItems: "center", mt: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Stack>

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h5 style={{ margin: 0 }}>Referral Data</h5>
            <CloseIcon onClick={handleClose} />
          </Box>
          {(referralData !== null) &&
            (<ol>
              {referralData.map((e) => (<li>{e.name} ({e.contact})</li>))}
            </ol>)
          }
        </Box>
      </Modal>
    </>
  );
}
