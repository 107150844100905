import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Grid,

} from "@mui/material";
import Swal from "sweetalert2";
import { apiAuth } from "../../utils/urls";
import { networkRequest } from "../../utils/network_request";
import { useLocation, useNavigate } from "react-router-dom";

export const AddMedals = () => {
  const { state } = useLocation();

  useEffect(() => {
    if (state?.data) {
      setFormData({
        id: state.data.id || null,
        name: state.data.name || "",
        limit: state.data.limit || "",
        description: state.data.description || "",
      });
    }
  }, [state?.data]);


  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    limit: "",
    description: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };




  const handleSubmit = async () => {

    const cleanMedalData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== null)
    );

    await networkRequest(
      apiAuth.createMedal,
      setLoading,
      (response) => {
        navigate(-1);
        if (state?.data) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Medal Updated successfully!",
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Medal created successfully!",
          });
        }

        setFormData({
          name: "",
          limit: "",
          medal_type: "post",
          level: "",
          village_limit: null,
          post_limit: "",
          validity: "",
        });
      },
      "post",
      cleanMedalData
    );
  };

  return (
    <>
      <Box sx={{ maxWidth: 600, px: 2 }}>
        <Grid
          container
          sx={{ my: 2 }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">Add Medal</Typography>
          </Grid>
        </Grid>
        <Box>
          {/* Medal Name */}
          <TextField
            id="name"
            label="Medal Name"
            variant="filled"
            type="text"
            fullWidth
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          <TextField
            id="limit"
            label="Add Limit"
            variant="filled"
            type="number"
            fullWidth
            name="limit"
            value={formData.limit}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          {/* Validity */}
          <TextField
            id="description"
            label="description"
            variant="filled"
            type="text"
            fullWidth
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            sx={{
              m: 1,
              "& .MuiInputBase-root": {
                height: "50px",
              },
              "& .MuiInputLabel-root": {
                lineHeight: "15px",
              },
            }}
          />

          <br />
          <Button
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? "Adding Medal..." : "Add Medal"}
          </Button>
        </Box>
      </Box>
    </>
  );
};
