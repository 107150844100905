// menuConfig.js
import { AllInbox, Inbox, Mail, PriceChange, Settings } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import GroupIcon from "@mui/icons-material/Group";
import ChecklistIcon from "@mui/icons-material/Checklist";
import FeedbackIcon from "@mui/icons-material/Feedback";
import KeyIcon from "@mui/icons-material/Key";

const menuConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    children: [],
  },
  {
    title: "Posts",
    path: "/posts",
    icon: <AutoAwesomeMotionIcon />,
    children: [],
  },
  {
    title: "Users",
    path: "/users",
    icon: <GroupIcon />,
    children: [],
  },
  {
    title: "Our Leader",
    path: "/users/leader",
    icon: <GroupIcon />,
    children: [],
  },
  {
    title: "Plans",
    path: "/plans",
    icon: <AllInbox />,
    children: [],
  },
  {
    title: "Medals",
    path: "/medals",
    icon: <PriceChange />,
    children: [],
  },
  {
    title: "Subscriptions",
    path: "/subscriptions",
    icon: <ChecklistIcon />,
    children: [],
  },
  {
    title: "Feedbacks",
    path: "/feedback",
    icon: <FeedbackIcon />,
    children: [],
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <Settings />,
    children: [],
  },
  {
    title: "Change Password",
    path: "/change-password",
    icon: <KeyIcon />,
    children: [],
  },
];

export default menuConfig;
